import _lru_cache from "./lru_cache";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var LruCache = _lru_cache;
var _ = _utils;
var pendingRequestsCount = 0;
var pendingRequests = {};
var maxPendingRequests = 6;
var sharedCache = new LruCache(10);

function Transport(o) {
  o = o || {};
  (this || _global).cancelled = false;
  (this || _global).lastReq = null;
  (this || _global)._send = o.transport;
  (this || _global)._get = o.limiter ? o.limiter((this || _global)._get) : (this || _global)._get;
  (this || _global)._cache = o.cache === false ? new LruCache(0) : sharedCache;
}

Transport.setMaxPendingRequests = function setMaxPendingRequests(num) {
  maxPendingRequests = num;
};

Transport.resetCache = function resetCache() {
  sharedCache.reset();
};

_.mixin(Transport.prototype, {
  _fingerprint: function (o) {
    o = o || {};
    return o.url + o.type + JSON.stringify(o.data || {});
  },
  _get: function (o, cb) {
    var that = this || _global,
        fingerprint,
        jqXhr;
    fingerprint = this._fingerprint(o); // #149: don't make a network request if there has been a cancellation
    // or if the url doesn't match the last url Transport#get was invoked with

    if ((this || _global).cancelled || fingerprint !== (this || _global).lastReq) {
      return;
    } // a request is already in progress, piggyback off of it


    if (jqXhr = pendingRequests[fingerprint]) {
      // jqXhr.done(done).fail(fail);
      jqXhr.then(done, fail);
    } // under the pending request threshold, so fire off a request
    else if (pendingRequestsCount < maxPendingRequests) {
        pendingRequestsCount++;
        pendingRequests[fingerprint] = // this._send(o).done(done).fail(fail).always(always);
        this._send(o).then(function (resp) {
          done(resp);
          always();
        }, function () {
          fail();
          always();
        });
      } // at the pending request threshold, so hang out in the on deck circle
      else {
          (this || _global).onDeckRequestArgs = [].slice.call(arguments, 0);
        }

    function done(resp) {
      cb(null, resp);

      that._cache.set(fingerprint, resp);
    }

    function fail() {
      cb(true);
    }

    function always() {
      pendingRequestsCount--;
      delete pendingRequests[fingerprint]; // ensures request is always made for the last query

      if (that.onDeckRequestArgs) {
        that._get.apply(that, that.onDeckRequestArgs);

        that.onDeckRequestArgs = null;
      }
    }
  },
  get: function (o, cb) {
    var resp, fingerprint;
    cb = cb || _.noop;
    o = _.isString(o) ? {
      url: o
    } : o || {};
    fingerprint = this._fingerprint(o);
    (this || _global).cancelled = false;
    (this || _global).lastReq = fingerprint; // in-memory cache hit

    if (resp = (this || _global)._cache.get(fingerprint)) {
      cb(null, resp);
    } // go to network
    else {
        this._get(o, cb);
      }
  },
  cancel: function () {
    (this || _global).cancelled = true;
  }
});

exports = Transport;
export default exports;