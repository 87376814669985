import _es6Promise from "es6-promise";
import _superagent from "superagent";
var exports = {};
var Promise = _es6Promise.Promise;
var request = _superagent;

exports = function (o) {
  return new Promise(function (resolve, reject) {
    request.get(o.url).end(function (err, res) {
      if (err) return reject(err);
      resolve(res.body);
    });
  });
};

export default exports;