import _persistent_storage from "./persistent_storage";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var PersistentStorage = _persistent_storage;
var _ = _utils;
var keys = {
  data: "data",
  protocol: "protocol",
  thumbprint: "thumbprint"
};
var location = null;

if (typeof window !== "undefined") {
  location = window.location;
} else {
  location = {
    protocol: "https:"
  };
} // constructor
// -----------
// defaults for options are handled in options_parser


function Prefetch(o) {
  (this || _global).url = o.url;
  (this || _global).ttl = o.ttl;
  (this || _global).cache = o.cache;
  (this || _global).prepare = o.prepare;
  (this || _global).transform = o.transform;
  (this || _global).transport = o.transport;
  (this || _global).thumbprint = o.thumbprint;
  (this || _global).storage = new PersistentStorage(o.cacheKey);
}

_.mixin(Prefetch.prototype, {
  _settings: function () {
    return {
      url: (this || _global).url,
      type: "GET",
      dataType: "json"
    };
  },
  store: function (data) {
    if (!(this || _global).cache) {
      return;
    }

    (this || _global).storage.set(keys.data, data, (this || _global).ttl);

    (this || _global).storage.set(keys.protocol, location.protocol, (this || _global).ttl);

    (this || _global).storage.set(keys.thumbprint, (this || _global).thumbprint, (this || _global).ttl);
  },
  fromCache: function () {
    var stored = {},
        isExpired;

    if (!(this || _global).cache) {
      return null;
    }

    stored.data = (this || _global).storage.get(keys.data);
    stored.protocol = (this || _global).storage.get(keys.protocol);
    stored.thumbprint = (this || _global).storage.get(keys.thumbprint); // the stored data is considered expired if the thumbprints
    // don't match or if the protocol it was originally stored under
    // has changed

    isExpired = stored.thumbprint !== (this || _global).thumbprint || stored.protocol !== location.protocol; // TODO: if expired, remove from local storage

    return stored.data && !isExpired ? stored.data : null;
  },
  fromNetwork: function (cb) {
    var that = this || _global,
        settings;

    if (!cb) {
      return;
    }

    settings = this.prepare(this._settings()); // this.transport(settings).fail(onError).done(onResponse);

    this.transport(settings).then(onResponse, onError);

    function onError() {
      cb(true);
    }

    function onResponse(resp) {
      cb(null, that.transform(resp));
    }
  },
  clear: function () {
    (this || _global).storage.clear();

    return this || _global;
  }
});

exports = Prefetch;
export default exports;