import _storage from "storage2";
import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var storage2 = _storage;
var _ = _utils;
var LOCAL_STORAGE;

try {
  LOCAL_STORAGE = storage2.localStorage; // while in private browsing mode, some browsers make
  // localStorage available, but throw an error when used

  LOCAL_STORAGE.setItem("~~~", "!");
  LOCAL_STORAGE.removeItem("~~~");
} catch (err) {
  LOCAL_STORAGE = null;
} // constructor
// -----------


function PersistentStorage(namespace, override) {
  (this || _global).prefix = ["__", namespace, "__"].join("");
  (this || _global).ttlKey = "__ttl__";
  (this || _global).keyMatcher = new RegExp("^" + _.escapeRegExChars((this || _global).prefix)); // for testing purpose

  (this || _global).ls = override || LOCAL_STORAGE; // if local storage isn't available, everything becomes a noop

  !(this || _global).ls && this._noop();
} // instance methods
// ----------------


_.mixin(PersistentStorage.prototype, {
  // ### private
  _prefix: function (key) {
    return (this || _global).prefix + key;
  },
  _ttlKey: function (key) {
    return this._prefix(key) + (this || _global).ttlKey;
  },
  _noop: function () {
    (this || _global).get = (this || _global).set = (this || _global).remove = (this || _global).clear = (this || _global).isExpired = _.noop;
  },
  _safeSet: function (key, val) {
    try {
      (this || _global).ls.setItem(key, val);
    } catch (err) {
      // hit the localstorage limit so clean up and better luck next time
      if (err.name === "QuotaExceededError") {
        this.clear();

        this._noop();
      }
    }
  },
  // ### public
  get: function (key) {
    if (this.isExpired(key)) {
      this.remove(key);
    }

    return decode((this || _global).ls.getItem(this._prefix(key)));
  },
  set: function (key, val, ttl) {
    if (_.isNumber(ttl)) {
      this._safeSet(this._ttlKey(key), encode(now() + ttl));
    } else {
      (this || _global).ls.removeItem(this._ttlKey(key));
    }

    return this._safeSet(this._prefix(key), encode(val));
  },
  remove: function (key) {
    (this || _global).ls.removeItem(this._ttlKey(key));

    (this || _global).ls.removeItem(this._prefix(key));

    return this || _global;
  },
  clear: function () {
    var i,
        keys = gatherMatchingKeys((this || _global).keyMatcher);

    for (i = keys.length; i--;) {
      this.remove(keys[i]);
    }

    return this || _global;
  },
  isExpired: function (key) {
    var ttl = decode((this || _global).ls.getItem(this._ttlKey(key)));
    return _.isNumber(ttl) && now() > ttl ? true : false;
  }
}); // helper functions
// ----------------


function now() {
  return new Date().getTime();
}

function encode(val) {
  // convert undefined to null to avoid issues with JSON.parse
  return JSON.stringify(_.isUndefined(val) ? null : val);
}

function decode(val) {
  return JSON.parse(val); // return $.parseJSON(val);
}

function gatherMatchingKeys(keyMatcher) {
  var i,
      key,
      keys = [],
      len = LOCAL_STORAGE.length;

  for (i = 0; i < len; i++) {
    if ((key = LOCAL_STORAGE.key(i)).match(keyMatcher)) {
      keys.push(key.replace(keyMatcher, ""));
    }
  }

  return keys;
}

exports = PersistentStorage;
export default exports;