import _utils from "./utils";
import _es6Promise from "es6-promise";
import _remote from "./remote";
import _prefetch from "./prefetch";
import _tokenizers from "./tokenizers";
import _options_parser from "./options_parser";
import _search_index from "./search_index";
import _transport from "./transport";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var _ = _utils;
var Promise = _es6Promise.Promise;
var Remote = _remote;
var Prefetch = _prefetch;
var tokenizers = _tokenizers;
var oParser = _options_parser;
var SearchIndex = _search_index;
var Transport = _transport;

function Bloodhound(o) {
  o = oParser(o);
  (this || _global).sorter = o.sorter;
  (this || _global).identify = o.identify;
  (this || _global).sufficient = o.sufficient;
  (this || _global).local = o.local;
  (this || _global).remote = o.remote ? new Remote(o.remote) : null;
  (this || _global).prefetch = o.prefetch ? new Prefetch(o.prefetch) : null; // the backing data structure used for fast pattern matching

  (this || _global).index = new SearchIndex({
    identify: (this || _global).identify,
    datumTokenizer: o.datumTokenizer,
    queryTokenizer: o.queryTokenizer
  }); // hold off on intialization if the intialize option was explicitly false

  o.initialize !== false && this.initialize();
}

Bloodhound.tokenizers = tokenizers;

_.mixin(Bloodhound.prototype, {
  // ### super secret stuff used for integration with jquery plugin
  __ttAdapter: function ttAdapter() {
    var that = this || _global;
    return (this || _global).remote ? withAsync : withoutAsync;

    function withAsync(query, sync, async) {
      return that.search(query, sync, async);
    }

    function withoutAsync(query, sync) {
      return that.search(query, sync);
    }
  },
  _loadPrefetch: function loadPrefetch() {
    var that = this || _global,
        promise,
        serialized;

    if (!(this || _global).prefetch) {
      return new Promise(function (resolve, reject) {
        resolve();
      });
    } else if (serialized = (this || _global).prefetch.fromCache()) {
      (this || _global).index.bootstrap(serialized);

      return new Promise(function (resolve, reject) {
        resolve();
      });
    } else {
      // this.prefetch.fromNetwork(done);
      return new Promise(function (resolve, reject) {
        // todo: promise
        that.prefetch.fromNetwork(function (err, data) {
          if (err) return reject(err);

          try {
            that.add(data);
            that.prefetch.store(that.index.serialize());
            resolve();
          } catch (e) {
            reject(e);
          }
        });
      });
    }
  },
  _initialize: function () {
    var that = this || _global,
        deferred; // in case this is a reinitialization, clear previous data

    this.clear();

    ((this || _global).initPromise = this._loadPrefetch()).then(addLocalToIndex); // local must be added to index after prefetch


    return (this || _global).initPromise;

    function addLocalToIndex() {
      that.add(that.local);
    }
  },
  // ### public
  initialize: function (force) {
    return !(this || _global).initPromise || force ? this._initialize() : (this || _global).initPromise;
  },
  // TODO: before initialize what happens?
  add: function (data) {
    (this || _global).index.add(data);

    return this || _global;
  },
  get: function (ids) {
    ids = _.isArray(ids) ? ids : [].slice.call(arguments);
    return (this || _global).index.get(ids);
  },
  search: function (query, sync, async) {
    var that = this || _global,
        local;
    local = this.sorter((this || _global).index.search(query)); // return a copy to guarantee no changes within this scope
    // as this array will get used when processing the remote results

    sync((this || _global).remote ? local.slice() : local);

    if ((this || _global).remote && local.length < (this || _global).sufficient) {
      (this || _global).remote.get(query, processRemote);
    } else if ((this || _global).remote) {
      // #149: prevents outdated rate-limited requests from being sent
      (this || _global).remote.cancelLastRequest();
    }

    return this || _global;

    function processRemote(remote) {
      var nonDuplicates = []; // exclude duplicates

      _.each(remote, function (r) {
        !_.some(local, function (l) {
          return that.identify(r) === that.identify(l);
        }) && nonDuplicates.push(r);
      });

      async && async(nonDuplicates);
    }
  },
  all: function () {
    return (this || _global).index.all();
  },
  clear: function () {
    (this || _global).index.reset();

    return this || _global;
  },
  clearPrefetchCache: function () {
    (this || _global).prefetch && (this || _global).prefetch.clear();
    return this || _global;
  },
  clearRemoteCache: function () {
    Transport.resetCache();
    return this || _global;
  },
  // DEPRECATED: will be removed in v1
  ttAdapter: function () {
    return this.__ttAdapter();
  }
});

exports = Bloodhound;
export default exports;