import _utils from "./utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
// inspired by https://github.com/jharding/lru-cache
var _ = _utils;

function LruCache(maxSize) {
  (this || _global).maxSize = _.isNumber(maxSize) ? maxSize : 100;
  this.reset(); // if max size is less than 0, provide a noop cache

  if ((this || _global).maxSize <= 0) {
    (this || _global).set = (this || _global).get = _.noop;
  }
}

_.mixin(LruCache.prototype, {
  set: function set(key, val) {
    var tailItem = (this || _global).list.tail,
        node; // at capacity

    if ((this || _global).size >= (this || _global).maxSize) {
      (this || _global).list.remove(tailItem);

      delete (this || _global).hash[tailItem.key];
      (this || _global).size--;
    } // writing over existing key


    if (node = (this || _global).hash[key]) {
      node.val = val;

      (this || _global).list.moveToFront(node);
    } // new key
    else {
        node = new Node(key, val);

        (this || _global).list.add(node);

        (this || _global).hash[key] = node;
        (this || _global).size++;
      }
  },
  get: function get(key) {
    var node = (this || _global).hash[key];

    if (node) {
      (this || _global).list.moveToFront(node);

      return node.val;
    }
  },
  reset: function reset() {
    (this || _global).size = 0;
    (this || _global).hash = {};
    (this || _global).list = new List();
  }
});

function List() {
  (this || _global).head = (this || _global).tail = null;
}

_.mixin(List.prototype, {
  add: function add(node) {
    if ((this || _global).head) {
      node.next = (this || _global).head;
      (this || _global).head.prev = node;
    }

    (this || _global).head = node;
    (this || _global).tail = (this || _global).tail || node;
  },
  remove: function remove(node) {
    node.prev ? node.prev.next = node.next : (this || _global).head = node.next;
    node.next ? node.next.prev = node.prev : (this || _global).tail = node.prev;
  },
  moveToFront: function (node) {
    this.remove(node);
    this.add(node);
  }
});

function Node(key, val) {
  (this || _global).key = key;
  (this || _global).val = val;
  (this || _global).prev = (this || _global).next = null;
}

exports = LruCache;
export default exports;