import _utils from "./utils";
import _transport from "./transport";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
 * typeahead.js
 * https://github.com/twitter/typeahead.js
 * Copyright 2013-2014 Twitter, Inc. and other contributors; Licensed MIT
 */
var _ = _utils;
var Transport = _transport;

function Remote(o) {
  (this || _global).url = o.url;
  (this || _global).prepare = o.prepare;
  (this || _global).transform = o.transform;
  (this || _global).transport = new Transport({
    cache: o.cache,
    limiter: o.limiter,
    transport: o.transport
  });
}

_.mixin(Remote.prototype, {
  // ### private
  _settings: function settings() {
    return {
      url: (this || _global).url,
      type: "GET",
      dataType: "json"
    };
  },
  get: function get(query, cb) {
    var that = this || _global,
        settings;

    if (!cb) {
      return;
    }

    query = query || "";
    settings = this.prepare(query, this._settings());
    return (this || _global).transport.get(settings, onResponse);

    function onResponse(err, resp) {
      err ? cb([]) : cb(that.transform(resp));
    }
  },
  cancelLastRequest: function cancelLastRequest() {
    (this || _global).transport.cancel();
  }
});

exports = Remote;
export default exports;